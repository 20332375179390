@import 'styles/theme';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'libs/shared/styles/background';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
html, body {
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
}

body {
  background: #19203A;
  color: white;
  overflow: hidden;

  //@include background-image('assets/background.png');
}

.z-top {
  z-index: 2147483647;
}

:root {
  --header-color: #6675b5;
  --header-border-radius: 8px;
}

.OT_ModalDialog {
  display: none !important;
}
