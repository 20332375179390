/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzFlM2Y2NyIsIj9lcjwjYmNjNWQxIiwiO2VyPCMxMjI5NGF$LCIlPmBePCM5OGI1NzQiLCI~ZXI8I2UwZTlkNSIsIjtlcjwjN2I5ZDU3fiwid2Fybj5gXjwjYzg0MDNlIiwiP2VyPCNlZmM2YzUiLCI7ZXI8I2I0MmEyOH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz5mYWxzZSwidmVyc2lvbj4xM30=
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@include mat.core();

/* Typography */
// Define the typography levels.
$headline1: mat.define-typography-level(98px, 112px, 300);
$headline2: mat.define-typography-level(61px, 56px, 300);
$headline3: mat.define-typography-level(49px, 48px, 300);
$headline4: mat.define-typography-level(35px, 40px, 300);
$headline5: mat.define-typography-level(24px, 24px, 300); // H1
$headline6: mat.define-typography-level(20px, 20px, 300); // H2
$subtitle1: mat.define-typography-level(16px, 20px, 300); // H3
$subtitle2: mat.define-typography-level(14px, 20px, 300); // H4
$body1: mat.define-typography-level(16px, 24px, 300); // Base body text
$body2: mat.define-typography-level(14px, 24px, 300);
$caption: mat.define-typography-level(12px, 20px, 400);
$button: mat.define-typography-level(14px, 14px, 500);

// Define the typography config.
$fontConfig: mat.define-typography-config(
  $font-family: 'proxima-nova',
  $headline-1: $headline1,
  $headline-2: $headline2,
  $headline-3: $headline3,
  $headline-4: $headline4,
  $headline-5: $headline5,
  $headline-6: $headline6,
  $body-1: $body1,
  $body-2: $body2,
  $subtitle-1: $subtitle1,
  $subtitle-2: $subtitle2,
  $button: $button,
  $caption: $caption
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base:              $light-text,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           $light-focused,
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);

// Compute font config
@include mat.typography-hierarchy($fontConfig);

// Theme Config

body {
  --primary-color: #1e3f67;
  --primary-lighter-color: #bcc5d1;
  --primary-darker-color: #12294a;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #1e3f67,
  lighter: #bcc5d1,
  darker: #12294a,
  200: #1e3f67, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: #98b574;
  --accent-lighter-color: #e0e9d5;
  --accent-darker-color: #7b9d57;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}
$mat-accent: (
  main: #98b574,
  lighter: #e0e9d5,
  darker: #7b9d57,
  200: #98b574, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: #c8403e;
  --warn-lighter-color: #efc6c5;
  --warn-darker-color: #b42a28;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #c8403e,
  lighter: #efc6c5,
  darker: #b42a28,
  200: #c8403e, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
;

$theme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn
  ),
  typography: $fontConfig,
  density: 0
));

//$theme: (
//  primary: $theme-primary,
//  accent: $theme-accent,
//  warn: $theme-warn,
//  is-dark: true,
//  foreground: $mat-dark-theme-foreground,
//  background: $mat-dark-theme-background,
//);

$altTheme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn
  ),
  typography: $fontConfig,
  density: 0
));

//$altTheme: (
//  primary: $theme-primary,
//  accent: $theme-accent,
//  warn: $theme-warn,
//  is-dark: false,
//  foreground: $mat-light-theme-foreground,
//  background: $mat-light-theme-background,
//);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-colors($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';
  .mat-badge-content {
    font-family: 'proxima-nova';
  }
}
